// :root {
//   --bs-menu: #2a3042;
//   --bs-menu-text: #a6b0cf;
//   --bs-menu-text-hover: #ffffff;
// }
.ngx-bar {
  background: #1e88e5 !important;
}

.pointer {
  cursor: pointer;
}

.btn:focus,
.btn:active,
.page-link:focus,
.page-link:active {
  outline: none !important;
  box-shadow: none !important;
}

.img-fluid.height {
  max-height: 100%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}

.carousel-inner {
  min-height: 200px !important;
  border-radius: $gallery-border-radius;
}

.chat-conversation .conversation-list .dropdown .dropdown-toggle {
  display: block;
}

.hide-arrow {
  .ng-arrow-wrapper {
    display: none !important;
  }
}

.pointer-event {
  pointer-events: none;
}

.lh-0 {
  line-height: 0;
}

.card {
  border-radius: 0.9rem !important;
}

.swal2-container {
  z-index: 9999 !important;
}

.treeview-container {
  max-height: 470px !important;
}

// .auth-full-page-content.custom {
//   background-color: burlywood !important;
// }
// .auth-full-page-content.custom .welcome1.custom {
//   color: red !important;
// }
// .auth-full-page-content.custom .welcome2.custom {
//   color: green !important;
// }
// .auth-full-page-content.custom div p.mb-0 {
//   color: blue !important;
// }
// .auth-full-page-content.custom .login-button.custom {
//   border-color: black;
//   color: black;
//   background-color: gray;
// }
// .auth-full-page-content.custom {
//   color: white !important;
// }
// .auth-full-page-content.custom a.text-muted {
//   color: white !important;
// }
