div {
  &.dt-buttons {
    float: right !important;
  }
  button {
    &.dt-button {
      margin-right: inherit !important;
      margin-bottom: inherit !important;
    }
  }
  &.dataTables_filter {
    float: left !important
  }
}

table.dataTable.table-striped>tbody>tr.odd>* {
  box-shadow: inherit !important;
}

table.dataTable.table-striped>tbody>tr.even>* {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.05);
}