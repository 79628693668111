@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
.gallery {
  position: relative;
  &.expired {
    opacity: 0.5;
  }
  img {
    width: 100%;
    height: 200px;
    object-position: center;
    &.cover {
      object-fit: cover;
    }
    &.scale {
      object-fit: scale-down;
    }
  }
  &.list {
    .carousel-inner {
      height: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
      width: 120px !important;
    }
    img {
      height: 100px;
    }
    .gallery-type {
      width: 25px;
      height: 25px;
    }
    @include media-breakpoint-up(sm) {
      .carousel-inner {
        height: 150px !important;
        min-height: 150px !important;
        max-height: 150px !important;
        width: 170px !important;
      }
      img {
        height: 150px;
      }
    }
  }
  .rounded {
    border-radius: $gallery-border-radius !important;
  }
  .gallery-box {
    display: flex;
    position: absolute;
    top: 8px;
    left: 8px;
    align-items: center;
    gap: 5px;
  }
  .gallery-type {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 10%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    z-index: 2;
    opacity: 1;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }
  .gallery-file-ext {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 10%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    min-width: 30px;
    width: auto;
    height: 30px;
    padding: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    z-index: 2;
    opacity: 1;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }
  .gallery-rating {
    color: #f1b44c;
    opacity: 1;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }
  .gallery-tools-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 8px;
    top: 8px;
    .gallery-tools {
      display: flex;
      flex-direction: column;
      align-items: center;
      visibility: visible;
      padding: 0;
      z-index: 2;
      opacity: 1;
      transition: visibility 0.3s linear, opacity 0.3s linear;
      .gallery-tools-item {
        background: rgba(255, 255, 255, 0.7);
        border-radius: 10%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4px;
      }
    }
  }
  .gallery-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    visibility: visible;
    position: absolute;
    left: 0;
    right: 100%;
    bottom: 0;
    width: 100%;
    padding: 8px;
    padding-top: 20px;
    opacity: 1;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    border-bottom-left-radius: $gallery-border-radius;
    border-bottom-right-radius: $gallery-border-radius;
  }
}

.gallery:hover {
  .gallery-tools-item:hover {
    background: rgba(255, 255, 255, 1);
  }
}


/*.gallery:hover {
  .gallery-type {
    visibility: visible;
    opacity: 1;
  }
  .gallery-rating {
    visibility: visible;
    opacity: 1;
  }
  .gallery-tools {
    visibility: visible;
    opacity: 1;
  }
  .gallery-tools-item:hover {
    background: rgba(255, 255, 255, 1);
  }
  .gallery-footer {
    visibility: visible;
    opacity: 1;
  }
}

.gallery.visible {
  visibility: visible;
  opacity: 1;
  .gallery-tools-container {
    .gallery-tools {
      visibility: visible;
      opacity: 1;
    }
  }
}*/
