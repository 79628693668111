//
// authentication.scss
//
// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.auth-pass-inputgroup {
  input[type="input"]+.btn .mdi-eye-outline {
    &:before {
      content: "\F06D1";
    }
  }
}

.auth-full-bg {
  background-color: rgba($primary, 0.25);
  display: flex;
  @media (min-width: 1200px) {
    height: 100vh;
  }
  &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }
  .bg-overlay {
    background-image: url("../../../images/bg-auth-overlay.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.7;
  }
}

.auth-full-page-content {
  display: flex;
  @media (min-width: 1200px) {
    min-height: 100vh;
  }
  .logo {
    background-image: url("../../../images/app_logo.png");
    background-repeat: no-repeat;
    width: 135px;
    height: 42px;
    margin: 0;
    padding: 0;
  }
}