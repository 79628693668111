.dd {
  display: block;
  font-size: 13px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.dd-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  min-height: 20px;
  padding: 0;
  position: relative;
}

.dd-handle {
  -moz-box-sizing: border-box;
  -webkit-border-radius: 3px;
  background: #f7f9fa;
  border: 1px solid #e4e5e7;
  border-radius: 3px;
  box-sizing: border-box;
  color: #333;
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  text-decoration: none;
}

.dd-handle span {
  font-weight: bold;
}

.dd-handle:hover {
  background: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

.dd-handle:hover {
  font-weight: inherit !important;
}

.dd-item {
  line-height: 24px !important;
}

.dd-placeholder,
.dd-empty {
  -moz-box-sizing: border-box;
  background: #f2fbff;
  border: 1px dashed #e4e5e7;
  box-sizing: border-box;
  margin: 5px 0;
  min-height: 30px;
  padding: 0;
}

.dd-empty {
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-position: 0 0, 30px 30px;
  background-size: 60px 60px;
  border: 1px dashed #bbb;
  min-height: 100px;
}

.dd-dragel {
  pointer-events: none;
  position: absolute;
  z-index: 9999;
}

.dd-dragel>.dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
}
