/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";
//
//Core files
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "variables";
@import "bootstrap/scss/mixins";
//
// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/right-sidebar-asset";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";
//
//Components
// @import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
// @import "custom/components/preloader";
@import "custom/components/forms";
// @import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
//
// Plugins
// @import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
// @import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
// @import "custom/plugins/rating";
// @import "custom/plugins/toastr";
@import "ngx-toastr/toastr";
// @import "custom/plugins/parsley";
@import "custom/plugins/select2";
// @import "custom/plugins/switch";
// @import "custom/plugins/colorpicker";
// @import "custom/plugins/datepicker";
// @import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
// @import "custom/plugins/form-wizard";
// @import "custom/plugins/datatable";
// @import "custom/plugins/responsive-table";
// @import "custom/plugins/apexcharts";
// @import "custom/plugins/chartist";
// @import "custom/plugins/echarts";
// @import "custom/plugins/google-map";
// @import "custom/plugins/leaflet-maps";
// @import "custom/plugins/owl-carousel";
//
// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
// @import "custom/pages/email";
// @import "custom/pages/file-manager";
@import "custom/pages/chat";
// @import "custom/pages/projects";
// @import "custom/pages/contacts";
// @import "custom/pages/crypto";
// @import "custom/pages/coming-soon";
@import "custom/pages/timeline";
// @import "custom/pages/extras-pages";
//
// rtl
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";
//
@import "local/modal.scss";
@import "local/dropzone.scss";
@import "local/datatables.scss";
@import "local/select.scss";
@import "local/select-spinner.css";
@import "local/nestable.scss";
@import "local/general.scss";
@import "local/gallery.scss";
@import "local/colors.scss";
@import "local/buttons.scss";
@import "styles.scss";
