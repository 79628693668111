$darkness: 0.25;
$primary-color: var(--bs-primary);
$secondary-color: var(--bs-secondary);
$success-color: var(--bs-success);
$info-color: var(--bs-info);
$warning-color: var(--bs-warning);
$danger-color: var(--bs-danger);
$dark-color: var(--bs-dark);
$menu-color: var(--bs-menu);
$menu-text-color: var(--bs-menu-text);
$menu-text-hover-color: var(--bs-menu-text-hover);
//
//----------------
//menus
//----------------
body[data-layout="horizontal"] {
  &.custom-theme {
    #page-topbar {
      background-color: $menu-color;
      .header-item {
        color: $menu-text-color;
        &:hover {
          color: $menu-text-color;
        }
      }
      .noti-icon {
        i {
          color: $menu-text-color;
        }
      }
    }
  }
}

body.custom-theme {
  .navbar-brand-box,
  .vertical-menu {
    background: $menu-color;
  }
  #sidebar-menu {
    ul {
      li {
        a {
          color: $menu-text-color;
          i {
            color: $menu-text-color;
          }
          &:hover {
            color: $menu-text-hover-color;
            i {
              color: $menu-text-hover-color;
            }
          }
        }
        ul.sub-menu {
          li {
            a {
              color: $menu-text-color;
              &:hover {
                color: $menu-text-hover-color;
              }
            }
          }
        }
      }
    }
  }
  // Enlarge menu
  &.vertical-collpsed {
    // Side menu
    .vertical-menu {
      // Sidebar Menu
      #sidebar-menu {
        >ul {
          >li {
            &:hover {
              >a {
                background-color: $menu-color;
                filter: saturate(0.6);
                color: $menu-text-hover-color;
                i {
                  color: $menu-text-hover-color;
                }
              }
              >ul {
                a {
                  color: $menu-text-color;
                  &:hover {
                    color: $menu-text-hover-color;
                  }
                }
              }
            }
          }
          ul {
            background-color: $menu-color;
          }
        }
        ul {
          li {
            &.mm-active .active {
              color: $menu-text-hover-color !important;
              i {
                color: $menu-text-hover-color !important;
              }
            }
          }
        }
      }
    }
  }
  .mm-active {
    color: $menu-text-hover-color !important;
    >a {
      color: $menu-text-hover-color !important;
      i {
        color: $menu-text-hover-color !important;
      }
    }
    >i {
      color: $menu-text-hover-color !important;
    }
    .active {
      color: $menu-text-hover-color !important;
      i {
        color: $menu-text-hover-color !important;
      }
    }
  }
  .menu-title {
    color: $menu-text-color;
  }
  //----------------
  //buttons
  //----------------
  .btn-primary {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:visited,
  .btn-primary:focus {
    background: linear-gradient(to top, rgba(0, 0, 0, $darkness), rgba(0, 0, 0, $darkness)) $primary-color !important;
  }
  .btn-secondary {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
  }
  .btn-secondary:hover,
  .btn-secondary:active,
  .btn-secondary:visited,
  .btn-secondary:focus {
    background: linear-gradient(to top, rgba(0, 0, 0, $darkness), rgba(0, 0, 0, $darkness)) $secondary-color !important;
  }
  .btn-success {
    background-color: $success-color !important;
    border-color: $success-color !important;
  }
  .btn-success:hover,
  .btn-success:active,
  .btn-success:visited,
  .btn-success:focus {
    background: linear-gradient(to top, rgba(0, 0, 0, $darkness), rgba(0, 0, 0, $darkness)) $success-color !important;
  }
  .btn-info {
    background-color: $info-color !important;
    border-color: $info-color !important;
  }
  .btn-info:hover,
  .btn-info:active,
  .btn-info:visited,
  .btn-info:focus {
    background: linear-gradient(to top, rgba(0, 0, 0, $darkness), rgba(0, 0, 0, $darkness)) $info-color !important;
  }
  .btn-warning {
    background-color: $warning-color !important;
    border-color: $warning-color !important;
  }
  .btn-warning:hover,
  .btn-warning:active,
  .btn-warning:visited,
  .btn-warning:focus {
    background: linear-gradient(to top, rgba(0, 0, 0, $darkness), rgba(0, 0, 0, $darkness)) $warning-color !important;
  }
  .btn-danger {
    background-color: $danger-color !important;
    border-color: $danger-color !important;
  }
  .btn-danger:hover,
  .btn-danger:active,
  .btn-danger:visited,
  .btn-danger:focus {
    background: linear-gradient(to top, rgba(0, 0, 0, $darkness), rgba(0, 0, 0, $darkness)) $danger-color !important;
  }
  .btn-dark {
    background-color: $dark-color !important;
    border-color: $dark-color !important;
  }
  .btn-dark:hover,
  .btn-dark:active,
  .btn-dark:visited,
  .btn-dark:focus {
    background: linear-gradient(to top, rgba(0, 0, 0, $darkness), rgba(0, 0, 0, $darkness)) $dark-color !important;
  }
  //----------------
  //backgrounds
  //----------------
  .bg-primary {
    background-color: $primary-color !important;
  }
  .bg-secondary {
    background-color: $secondary-color !important;
  }
  .bg-success {
    background-color: $success-color !important;
  }
  .bg-info {
    background-color: $info-color !important;
  }
  .bg-warning {
    background-color: $warning-color !important;
  }
  .bg-danger {
    background-color: $danger-color !important;
  }
  .bg-dark {
    background-color: $dark-color !important;
  }
  //----------------
  //texts
  //----------------
  .text-primary {
    color: $primary-color !important;
  }
  .text-secondary {
    color: $secondary-color !important;
  }
  .text-success {
    color: $success-color !important;
  }
  .text-info {
    color: $info-color !important;
  }
  .text-warning {
    color: $warning-color !important;
  }
  .text-danger {
    color: $danger-color !important;
  }
  .text-dark {
    color: $dark-color !important;
  }
}