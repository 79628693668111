.btn {
  &.nav-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    box-shadow: none;
    padding: 0;
    font-size: 16px;
    background-color: #eff2f7;
    border-radius: 50%;
    --bs-btn-disabled-border-color: #eff2f7;
    --bs-btn-active-color: var(--bs-btn-color);
    --bs-btn-active-bg: #eff2f7;
    --bs-btn-active-border-color: #eff2f7;
  }
}