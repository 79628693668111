/* You can add global styles to this file, and also import other style files */

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.png-background {
  background: url(/assets/images/png_bkg.jpg);
  background-size: cover;
}

button {
  white-space: normal !important;
}

.btn-soft-success {
  color: #34c38f;
  background-color: #34c38f1a;
  border-color: transparent;
  transition: all 0.5s ease;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #34c38f;
  --bs-btn-active-border-color: #34c38f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success:active {
  color: #fff !important;
  background-color: #34c38f !important;
  border-color: transparent !important;
}