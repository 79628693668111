.modal-content {
  border: initial;
  border-radius: 0;
  .modal-header {
    background-color: $sidebar-dark-bg;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .modal-title {
      color: black;
      text-transform: uppercase;
    }
    .btn-close {
      color: black;
    }
  }
}