.ng-select .ng-dropdown-panel .ng-option.ng-option-selected.ng-option-marked {
  color: #495057 !important;
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  background-color: #f5f5f5 !important;
  cursor: default;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 8px !important;
}

ng-select.is-invalid>.ng-select-container {
  border-color: #f46a6a !important;
}